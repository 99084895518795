<template>
  <div class="message-edit">
    <Header />
    <div class="content type">
      <div class="item" @click="currentSmsType=1">作物</div>
      <div class="item" @click="currentSmsType=2">会议促销</div>
      <div class="item" @click="currentSmsType=3">病虫害预防</div>
    </div>
    <TextArea class="content textarea" label="短信内容" :required="false" v-model="msgInfo.smsContent"/>
    <RowLink 
      class="content links" 
      :links="links"
      @termial="isShowTermialModal=true"
      @customer="isShowCustomerModal=true"
      @grower="isShowGrowerModal=true"
    />
    <div class="button-container">
      <Button class="button" size="large" @click="batchSend">短信群发</Button>
      <Button class="button" size="large-plain" @click="sendTestMessage">发送测试</Button>
    </div>
    <div class="template-container" v-if="currentSmsType || isShowTermialModal || isShowCustomerModal || isShowGrowerModal">
      <MessageTemplate
        v-if="currentSmsType"
        :smsType="currentSmsType" 
        @confirm="changeTemplate" 
        @close="currentSmsType=0"
      />
      <TerminalSelect 
        selectType="terminal" 
        userType="shidanli"
        v-model="terminalList"
        v-if="isShowTermialModal"
        @confirm="isShowTermialModal = !isShowTermialModal"
      />
      <GrowerSelect
        selectType="grower" 
        userType="shidanli"
        v-model="growerList"
        v-if="isShowGrowerModal"
        @confirm="isShowGrowerModal=false"
      />
      <CustomerSelect 
        selectType="potential" 
        userType="shidanli"
        v-model="customerList"
        v-if="isShowCustomerModal"
        @confirm="isShowCustomerModal = !isShowCustomerModal"
      />
    </div>
  </div>
</template>

<script>
import { pushTestMessage, sendMessage } from '@/api/message';
import MessageTemplate from './template';
import Header from '@/components/header';
import TextArea from '@/components/common/textArea';
import RowLink from '@/components/select/rowLink';
import Button from '@/components/common/button/CustomButton';
import TerminalSelect from '@/views/select/blockSelect';
import CustomerSelect from '@/views/select/textSelect';
import GrowerSelect from '@/views/select/blockSelect';
export default {
  components: {
    Header,
    Button,
    RowLink,
    TextArea,
    GrowerSelect,
    TerminalSelect,
    MessageTemplate,
    CustomerSelect
  },
  data() {
    return {
      currentSmsType: 0,
      isShowGrowerModal: false,
      isShowTermialModal: false,
      isShowCustomerModal: false,
      msgInfo: {},
      terminalList: [],
      growerList: [],
      customerList: [],
      links: [
        { label: '选择终端网点', event: 'termial' },
        { label: '选择种植大户', event: 'grower' },
        { label: '选择潜在客户', event: 'customer' },
      ]
    }
  },
  activated() {
    this.msgInfo = this.$route.params || {};
  },
  methods: {
    changeTemplate(val) {
      this.currentSmsType = 0;
      this.msgInfo.smsContent = val.smsContent;
    },
    async sendTestMessage() {
      if (!this.msgInfo.smsContent) {
        return this.$toast('请输入短信内容');
      }
      try {
        await pushTestMessage({
          kjDealer: this.msgInfo.kjDealer || '',
          smsContent: this.msgInfo.smsContent || '',
          sendType: this.msgInfo.sendType || '',
          dealerName: this.msgInfo.dealerName || '',
        });
        this.$toast('短信发送成功');
      } catch (e) {
        this.$toast(e.message || '短信发送失败');
      }

    },
    async batchSend() {
      try {
        if (!this.msgInfo.smsContent) {
          return this.$toast('请输入短信内容');
        }
        const terminalList = this.terminalList.map((item) => {
          return {
            receiveName: item.contactPerson,
            receiveType: 1,
            receiveMobile: item.mobile,
            receiveId: item.id,
          }
        });
        const growerList = this.growerList.map((item) => {
          return {
            receiveName: item.bigfarmersPerson,
            receiveType: 2,
            receiveMobile: item.bigfarmersTel,
            receiveId: item.id,
          }
        });
        const customerList = this.customerList.map((item) => {
          return {
            receiveName: item.contactPerson,
            receiveType: 3,
            receiveMobile: item.mobile,
            receiveId: item.id
          }
        });
        await sendMessage({
          kjDealer:  this.msgInfo.kjDealer || '',
          smsContent: this.msgInfo.smsContent || '',
          sendType: this.msgInfo.sendType || '',
          dealerName: this.msgInfo.dealerName || '',
          sendDetails: [...terminalList, ...growerList, ...customerList],
        });
        this.$toast('短信发送成功');
      } catch (e) {
        this.$toast(e.message || '短信发送失败');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-edit {
  min-height: 100vh;
  background-color: #FAFBFC;
  .content {
    margin-top: 12px;
    width: 100%;
    background-color: #fff;
    padding: 10px 24px;
    box-sizing: border-box;

    &.type {
      padding-left: 54px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #818181;
    }
    &.links {
      padding: 0;
    }
  }
  .button-container {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 24px 28px;
    box-sizing: border-box;
    .button {
      margin-top: 12px;
    }
  }
  .template-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    z-index: 100;
  }
}
</style>